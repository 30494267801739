import React from "react"
import styled from "styled-components"
import { Media } from "../../utilities"

const StyledGrid = styled.div`
  display: grid;
  width: 100%;
  height: 450px;
  grid-template-columns: 1fr 1fr 1fr;

  ${Media.below.tablet`
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
  `}
`

const ImageBlock = styled.img`
  flex: 1;
  object-fit: cover;
  height: 100%;
  width: 100%;

  ${Media.below.tablet`
    height: 200px;
  `}
`

export const ImageGrid = ({ images = [], ...props }) => (
  <StyledGrid {...props}>
    {images.map((currentImage, ix) => (
      <ImageBlock src={currentImage.image} alt={currentImage.altTag} key={ix} />
    ))}
  </StyledGrid>
)
